import {setCookie} from './manage-cookies';
import {conversionHit, retargetingHit, SeznameConversionHit} from './manage-seznam';
import {fbq} from './manage-fbq';

const cookiesContainer = document.getElementById('cookies-container');

export type CookiePreference = 'necessary' | 'analytical' | 'marketing' | 'other';
export const cookiePreferences: {
	[key in CookiePreference]: boolean | undefined
} = {
	necessary: true,
	analytical: undefined,
	marketing: undefined,
	other: undefined,
};

const seznamIdentifiers: {
	sklikId?: number,
	retargetingId?: number,
} = {};

const closePopup = () => {
	cookiesContainer.style.display = 'none';
};
const showPopup = () => {
	cookiesContainer.style.display = 'block';
	window.scrollTo(0, 0);
};
const switchToDetailed = () => {
	const cookieSimple = document.getElementById('cookie-simple');
	const cookieDetailed = document.getElementById('cookie-detailed');

	cookieSimple.style.display = 'none';
	cookieDetailed.style.display = 'block';
};

const triggerGtag = () => gtag('consent', 'update', {
	ad_user_data: cookiePreferences.marketing ? 'granted' : 'denied',
	ad_personalization: cookiePreferences.marketing ? 'granted' : 'denied',
	ad_storage: cookiePreferences.marketing ? 'granted' : 'denied',
	analytics_storage: cookiePreferences.analytical ? 'granted' : 'denied',
});

const triggerFbq = () => fbq('consent', cookiePreferences.analytical ? 'grant' : 'revoke');

const triggerSeznamConversion = (args: Partial<SeznameConversionHit>) => {
	conversionHit({
		id: seznamIdentifiers.sklikId,
		consent: cookiePreferences.analytical ? 1 : 0,
		... args
	});
};
const triggerSeznamRetargeting = () => {
	retargetingHit({
		rtgId: seznamIdentifiers.retargetingId,
		consent: cookiePreferences.marketing ? 1 : 0,
	});
};

const persistToCookies = () => {
	setCookie('COOKIES_consent', '1', {days: 365});
	setCookie('COOKIES_analytical', cookiePreferences.analytical ? '1' : '0', {days: 365});
	setCookie('COOKIES_marketing', cookiePreferences.marketing ? '1' : '0', {days: 365});
	setCookie('COOKIES_other', cookiePreferences.other ? '1' : '0', {days: 365});

	triggerGtag();
	triggerFbq();
	triggerSeznamRetargeting();
}

const approveCookieClick = (e: Event) => {
	e.preventDefault();

	cookiePreferences.analytical = true;
	cookiePreferences.marketing = true;
	cookiePreferences.other = true;

	persistToCookies();

	closePopup();
}

const denyCookieClick = (e: Event) => {
	e.preventDefault();

	cookiePreferences.analytical = false;
	cookiePreferences.marketing = false;
	cookiePreferences.other = false;

	persistToCookies();

	closePopup();
}

const detailedCookieClick = (e: Event) => {
	e.preventDefault();

	switchToDetailed();
}

const openCookieSettingsClick = (e: Event) => {
	e.preventDefault();

	switchToDetailed();
	showPopup();
}

const saveCookieClick = (e: Event) => {
	e.preventDefault();

	const cookieAnalyticalOption = document.getElementById('cookie-analytical') as HTMLInputElement;
	const cookieMarketingOption = document.getElementById('cookie-marketing') as HTMLInputElement;
	const cookieOtherOption = document.getElementById('cookie-other') as HTMLInputElement;

	cookiePreferences.analytical = cookieAnalyticalOption.checked;
	cookiePreferences.marketing = cookieMarketingOption.checked;
	cookiePreferences.other = cookieOtherOption.checked;

	persistToCookies();

	closePopup();
}

const init = (arg: {
	hasUserConsent: boolean;
	analyticsConsent: boolean;
	marketingConsent: boolean;
	otherConsent: boolean;
	seznamSklikId: number;
	seznamRetargetingId: number;
	googleAnalytics: string;
	googleTagManager: string;
	facebookAppId: string;
}) => {
	cookiePreferences.analytical = arg.analyticsConsent;
	cookiePreferences.marketing = arg.marketingConsent;
	cookiePreferences.other = arg.otherConsent;

	seznamIdentifiers.sklikId = arg.seznamSklikId;
	seznamIdentifiers.retargetingId = arg.seznamRetargetingId;

	gtag('consent', 'default', {
		'ad_user_data': 'denied',
		'ad_personalization': 'denied',
		'ad_storage': 'denied',
		'analytics_storage': 'denied',
		'wait_for_update': 500,
	});
	gtag('js', new Date());
	gtag('config', arg.googleTagManager);

	triggerGtag();
	triggerSeznamRetargeting();
};

document.querySelectorAll('.open-cookie-settings').forEach((userItem) => {
	userItem.addEventListener('click', openCookieSettingsClick)
});

const approveCookieButton = document.getElementById('button-cookie-approve');
approveCookieButton.addEventListener('click', approveCookieClick);

const denyCookieButton = document.getElementById('button-cookie-deny');
denyCookieButton.addEventListener('click', denyCookieClick);

const detailedCookieButton = document.getElementById('button-cookie-detailed');
detailedCookieButton.addEventListener('click', detailedCookieClick);

const saveCookieButton = document.getElementById('button-cookie-save');
saveCookieButton.addEventListener('click', saveCookieClick);

const cookieInitJson = document.getElementById('cookie-init');

init(JSON.parse(cookieInitJson.textContent));

const seznamConversionHit = document.getElementById('seznam-conversion-hit');

if (seznamConversionHit !== null && seznamConversionHit !== undefined)
{
	triggerSeznamConversion(JSON.parse(seznamConversionHit.textContent));
}
